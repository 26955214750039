import {action, computed, IObjectDidChange, observable, observe} from "mobx"
import {Gate} from "./lib/gate/Gate"
import {History} from "history"
import {User, Visitor} from "./lib/gate/interfaces"
import {Router} from "./models/Router"
import {Authorisation} from "./models/Authorisation"
import {DELIMITER, ONE_TIME_PASSWORD_TYPES} from "lib/functions/helper"

export class Model {
    public readonly router: Router
    public readonly auth: Authorisation

    @observable
    public visitor: Visitor

    @observable
    public staffUsers: User[] = []

    @observable
    public staffUsersIsLoading = false

    @observable
    public appSettings: any

    @observable
    public popup = {visible: false, message: ""}

    @observable
    public isResetPasswordLinkActive: boolean = false

    @observable
    public howDidYouKnowAboutUsList: any[] = []

    constructor(public readonly gate: Gate, private history: History) {
        this.history = history

        this.auth = new Authorisation(gate, localStorage)
        this.router = new Router(history, this.auth)
        // this.auth.refreshToken()
        observe(this.auth, this.onAuthorisationChange.bind(this))
    }

    private onAuthorisationChange(change: IObjectDidChange) {
        if (change.name !== "status") {
            // Parameter not changed
            return
        }

        if (this.auth.status === "authorised" && this.auth.authorisedByToken === false) {
            this.loadMe()
        }
    }

    @computed
    public get planStaffUserId(): number {
        if (this.appSettings && this.appSettings.plan_staff_user_id) {
            return parseInt(this.appSettings.plan_staff_user_id)
        }

        return 0
    }

    @computed
    public get agreementStaffUserId(): number {
        // return 60347
        if (this.appSettings && this.appSettings.agreement_staff_user_id) {
            return parseInt(this.appSettings.agreement_staff_user_id)
        }

        return 0
    }

    public get isExpired(): boolean {
        return this.isResetPasswordLinkActive
    }

    public set isExpired(status: boolean) {
        this.isResetPasswordLinkActive = status
    }

    @action
    public alert(message: string) {
        this.popup.visible = true
        this.popup.message = message
    }

    public get isStaff(): boolean {
        if (!this.visitor) {
            return false
        }
        const role = this.visitor.role
        if (role === "staff" || role === "admin") {
            return true
        }

        return false
    }

    public async updatePassword({password, token}): Promise<any> {
        return await this.gate.request("/accounts/reset/password", {
            data: {password, token}
        })
    }

    public async checkResetPasswordLink(token: string): Promise<any> {
        const response = await this.gate.request("/accounts/check", {
            data: {
                token
            }
        })
        if (response.success) {
            const {
                data: {expires}
            } = response
            this.isExpired = expires
        } else {
            const {
                errors: [{data}]
            } = response
            const keyExpires = "expires"
            this.isExpired = data[keyExpires]
        }
        return response
    }

    public async loadStaffUsers() {
        this.staffUsersIsLoading = true
        let r = await this.gate.request("/users/get-all-staff")
        this.staffUsersIsLoading = false
        this.staffUsers = r.data
    }

    public async loadAppSettings() {
        let r = await this.gate.request("/app-settings/get")
        this.appSettings = r.data.json_data
    }

    public async loadMe(): Promise<void> {
        let r = await this.gate.request("/users/get-me")
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.visitor = r.data
    }

    public getHistory(): History {
        return this.history
    }

    public async getCaptcha(): Promise<any> {
        return await this.gate.request("/captchas", {
            data: {
                slug: "naa"
            }
        })
    }

    public async sendCreateAccountOtpEmail(email: string, slug: string = "naa"): Promise<any> {
        let r: any = await this.gate.request("/v3/accounts/auth/one-time-password/send", {
            data: {email, slug, type: ONE_TIME_PASSWORD_TYPES.signUp}
        })
        return r
    }

    public async sendOtpEmail(email: string, slug: string = "naa"): Promise<any> {
        let r: any = await this.gate.request("/v3/accounts/recovery", {
            data: {email, slug, type: ONE_TIME_PASSWORD_TYPES.webReset}
        })
        return r
    }

    public async activateEmailAccount(credentials: any, slug: string = "naa"): Promise<any> {
        let r: any = await this.gate.request("/v3/accounts/activate", {
            data: {
                ...credentials,
                slug
            }
        })
        return r
    }

    public async getHowDidYouKnowAboutUs(slug: string = "naa") {
        let r: any = await this.gate.request("/how-did-you-know-about-us/list", {
            data: {
                slug
            }
        })
        if (!r.success) {
            this.alert("API Error!" + JSON.stringify(r.errors))
        }
        this.howDidYouKnowAboutUsList = r.data.map(({id, name}) => ({
            id,
            value: `${id}${DELIMITER}${name}`,
            label: name
        }))
    }
}
