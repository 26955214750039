import React, {useState} from "react"
import styles from "./Tooltip.module.css"

const Tooltip = (props) => {
    let timeout
    const [active, setActive] = useState(false)

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true)
        }, props.delay || 400)
    }

    const hideTip = () => {
        clearInterval(timeout)
        setActive(false)
    }

    return (
        <div
            className={`${styles.TooltipWrapper}`}
            // When to show the tooltip
            onMouseEnter={showTip}
            onMouseLeave={hideTip}>
            {/* Wrapping */}
            {props.children}
            {active && (
                <div
                    className={`${styles.TooltipTip} ${
                        props.direction ? `${styles[props.direction]}` : styles.top
                    }`}>
                    {/* Content */}
                    {props.content}
                </div>
            )}
        </div>
    )
}

export {Tooltip}
