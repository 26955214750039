import React, {Component} from "react"
import styles from "./Header.module.css"
import naaLogo from "./parts/img/naa-logo.webp"
import {ReactComponent as SignatureIcon} from "./parts/img/signature.svg"

interface Props {}

export class Header extends Component<Props, {}> {
    public render() {
        return (
            <header className={styles.root}>
                <div className={styles.wrapper}>
                    <h1 className={styles.h1}>
                        <img src={naaLogo} alt="naa-logo" />
                    </h1>
                    <div className={styles.rightWrapper}>
                        <SignatureIcon className={styles.signatureIcon} />
                        <span className={styles.text}>APPLY TO NAA</span>
                    </div>
                </div>
            </header>
        )
    }
}
